import constants from "../constants/authConstants";
import { push } from "react-router-redux";
import { validateFields } from "../helpers/validationHelper";
import { setupRequest } from "../helpers/formHelper";
import { post, get } from "../services/apiService";
import authHelper from "../helpers/authHelper";

export const actionCreators = {
  login: inputFields => async (dispatch, getState) => {
    dispatch({
      type: constants.LOGIN_REQUEST
    });

    let errors = validateFields(inputFields);

    if (errors === undefined || errors.length === 0) {
      let request = setupRequest(inputFields);
      let response = await post("User", "Login", request);

      if (response.success) {
        const auth = response.data;
        dispatch({
          type: constants.LOGIN_SUCCESS,
          auth
        });
        dispatch(push("/"));
        authHelper.storeAuth(auth);
      } else {
        errors = response.data;
        dispatch({
          type: constants.LOGIN_FAILURE,
          errors
        });
      }
    } else {
      dispatch({
        type: constants.LOGIN_FAILURE,
        errors
      });
    }
  },

  getAuth: () => async (dispatch, getState) => {
    const auth = await authHelper.getAuth();

    if (auth != null) {
      dispatch({
        type: constants.AUTH_FOUND,
        auth
      });
    }
  },

  logout: accessToken => async (dispatch, getState) => {
    authHelper.deleteAuth();
    dispatch({
      type: constants.AUTH_REMOVED
    });
    dispatch(push("/"));
  },

  unmount: () => async (dispatch, getState) => {
    dispatch({
      type: constants.UNMOUNT
    });
  },

  refreshAuth: refreshToken => async (dispatch, getState) => {
    dispatch({
      type: constants.AUTH_REFRESH_REQUEST
    });
    authHelper.deleteAuth();

    let errors = [];

    let response = await get("User", "RefreshToken", {
      Token: refreshToken
    });

    if (response.success) {
      const auth = response.data;
      authHelper.storeAuth(auth);
      dispatch({
        type: constants.AUTH_REFRESH_SUCCESS,
        auth
      });
    } else {
      errors = response.data;
      dispatch({
        type: constants.AUTH_REFRESH_FAILURE,
        errors
      });
    }
  },

  googleLogin: googleToken => async (dispatch, getState) => {

    dispatch({ type: constants.LOGIN_REQUEST });

    //await dispatch(push("/login"));

    let errors = [];
    let response = await get("User", "GoogleLogin", {
      Token: googleToken
    });

    if (response.success) {
      const auth = response.data;
      authHelper.storeAuth(auth);
      dispatch({
        type: constants.LOGIN_SUCCESS,
        auth
      });
      dispatch(push("/"));
    } else {
      errors = response.data;
      dispatch({
        type: constants.LOGIN_FAILURE,
        errors
      });
    }
  }
};
