export const authConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    AUTH_FOUND: 'AUTH_FOUND',
    AUTH_REMOVED: 'AUTH_REMOVED',

    UNMOUNT: 'UNMOUNT',

    AUTH_REFRESH_REQUEST: 'AUTH_REFRESH_REQUEST', 
    AUTH_REFRESH_SUCCESS: 'AUTH_REFRESH_SUCCESS', 
    AUTH_REFRESH_FAILURE: 'AUTH_REFRESH_FAILURE',


    ACCESS_TOKEN: 'ACCESS_TOKEN',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    EMAIL_ADDRESS: 'EMAIL_ADDRESS',
    USER_ID: 'USER_ID'
 
};

export default authConstants;
