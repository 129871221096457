import constants from "../constants/emailConstants";
import { validateFields } from "../helpers/validationHelper";
import { setupRequest } from "../helpers/formHelper";
import { post, get } from "../services/apiService";

export const actionCreators = {
  confirm: token => async (dispatch, getState) => {
    dispatch({
      type: constants.CONFIRM_REQUEST
    });

    let response = await get("User", "ConfirmEmail", {
      Token: token
    });

    if (response.success) {
      dispatch({
        type: constants.CONFIRM_SUCCESS
      });
    } else {
      let errors = response.data;
      dispatch({
        type: constants.CONFIRM_FAILURE,
        errors
      });
    }
  },

  resend: inputFields => async (dispatch, getState) => {
    dispatch({
      type: constants.RESEND_REQUEST
    });

    let errors = validateFields(inputFields);

    if (errors === undefined || errors.length === 0) {
      let request = setupRequest(inputFields);

      let response = await get("User", "ResendEmail", request);

      if (response.success) {
        dispatch({
          type: constants.RESEND_SUCCESS
        });
      } else {
        errors = response.data;
        dispatch({
          type: constants.RESEND_FAILURE,
          errors
        });
      }
    } else {
      dispatch({
        type: constants.RESEND_FAILURE,
        errors
      });
    }
  },
  reset: () => async (dispatch, getState) => {
    dispatch({
      type: constants.RESET_STATE
    });
  }
};
