import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from './actions/authActions';
import decode from 'jwt-decode';




class Startup extends Component {

  constructor(props) {
    super(props);
  }

  async componentWillMount() {

    //check if we have a token in local storage

    await this.props.getAuth();


    //if not, verify with api that access token is still valid

    this.authCheck();
  }

  componentDidUpdate() {

    this.authCheck();

  }

  async authCheck() {
    if (this.props.authenticated && !this.props.refreshing) {

      let decoded = decode(this.props.auth.accessToken);

      //check if access token is still valid (not expired)
      if (decoded.exp < new Date().getTime() / 1000) {

        //use refresh token to renew access token
        await this.props.refreshAuth(this.props.auth.refreshToken);
      }
    }
  }



  render() {
    return this.props.children;
  }
}


export default connect(
  state => state.auth,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Startup);


