


export const registerConstants = {
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',
    UNMOUNT: 'UNMOUNT'

    
};

export default registerConstants;
