import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../actions/registerActions';
import { renderErrors } from "../components/InputErrors";
import renderInputField from "../components/BasicTextField";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import OAuth from '../components/OAuth';

class Register extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputFields: [
        {
          id: 'FirstName',
          name: 'First Name',
          required: false
        },
        {
          id: 'LastName',
          name: 'Last Name',
          required: false
        },
        {
          id: 'Email',
          name: 'Email Address',
          required: true
        },
        {
          id: 'Password',
          name: 'Password',
          type: 'password',
          required: true
        },
        {
          id: 'PasswordConfirm',
          name: 'Confirm Password',
          type: 'password',
          required: true
        }
      ]
    };

    this.submitRegister = this.submitRegister.bind(this);
  }

  componentWillUnmount() {
    this.props.unmount();
  }

  submitRegister = e => {
    e.preventDefault();
    this.props.register(this.state.inputFields);


  };


  render() {

    const { classes } = this.props;
    if (this.props.reg.success) {
      return (
        <React.Fragment>
          <main className={classes.successLayout}>
            <Paper className={classes.paper}>

              <DoneIcon className={classes.icon} />
              <Typography variant="h5">
                Success
              </Typography>
              <Typography variant="body1" align="center" color="textSecondary" paragraph>
                A confirmation email has been sent to {this.state.inputFields[2].value}. Click on the confirmation link in the email to activate your account.
              </Typography>
            </Paper>
          </main>


        </React.Fragment>
      );

    } else {
      return (
        <React.Fragment>
          <main className={classes.formLayout}>
            <Paper className={classes.paper}>

              <AccountIcon className={classes.icon} />

              <Typography variant="h5">
                Sign Up
              </Typography>

              <div className={classes.error}>
                {renderErrors('*', this.props)}
              </div>

              <form className={classes.form}>

                {this.state.inputFields.map(inputField => (
                  renderInputField(inputField, this)
                ))}

                {this.renderSubmitButton()}
              </form>
              <Typography variant="body1" paragraph>
                Already have an account? Sign in <Link to="/login">here</Link>
              </Typography>
              <Divider className={classes.divider} />

              <OAuth />

            </Paper>

          </main>
        </React.Fragment>

      );
    }
  }



  renderSubmitButton() {
    const { classes } = this.props;


    if (this.props.reg.loading || this.props.auth.loggingIn) {
      return (
        <FormControl fullWidth>
          <CircularProgress className={classes.progress} size={50} />
        </FormControl>

      );
    } else {
      return (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={this.submitRegister}
        >
          Submit
          </Button>
      );
    }
  }
}

const styles = theme => ({
  formLayout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing.unit * 4
    },
  },
  successLayout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up(700 + theme.spacing.unit * 3 * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    paddingTop: theme.spacing.unit * 5,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    backgroundColor: theme.palette.primary.paper
  },
  icon: {
    color: theme.palette.text.primary
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit
  },
  progress: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  divider: {
    marginTop: theme.spacing.unit,
    width: '80%',
    marginBottom: theme.spacing.unit * 3,

  },
  error: {
    marginTop: theme.spacing.unit
  }
});


const mapStateToProps = state => ({
  auth: state.auth,
  reg: state.register,
  errors: state.auth.errors.concat(state.register.errors)
});


export default connect(
  mapStateToProps,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(styles)(Register));
