import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import Button from "@material-ui/core/Button";
import queryString from 'query-string'
import ErrorIcon from '@material-ui/icons/Error';
import { renderErrors } from "./InputErrors";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../actions/emailActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';


class EmailConfirm extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {

        const values = queryString.parse(this.props.location.search);

        this.props.confirm(values.token);
    }

    render() {
        const { classes } = this.props;

        if (this.props.loading) {

            return (
                <React.Fragment>
                    <main className={classes.layoutConfirm}>
                        <CircularProgress className={classes.progress} size={50} />
                    </main>
                </React.Fragment>
            );

        } else {

            if (this.props.success) {
                return (
                    <React.Fragment>
                        <main className={classes.layoutConfirm}>

                            <CheckIcon className={classes.check} fontSize="large" />
                            <Typography variant="h4">
                                Success
                            </Typography>
                            <br />
                            <Typography variant="body1" align="center" color="textSecondary" paragraph>
                                Your email address has been confirmed, click the button below to login
                            </Typography>

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.back}
                                onClick={() => this.props.history.push('/login')}
                            >
                                Login
                            </Button>
                        </main>

                    </React.Fragment >
                );
            } else {
                return (
                    <React.Fragment>
                        <main className={classes.layoutConfirm}>

                            <ErrorIcon color="error" fontSize="large" />
                            <Typography variant="h4">
                                Error
                            </Typography>
                            <br />

                            {renderErrors('*', this.props)}

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.back}
                                onClick={() => this.props.history.push('/emailResend')}
                            >
                                Resend Link
                            </Button>
                        </main>

                    </React.Fragment >
                );
            }
        }
    }
}
const styles = theme => ({
    layoutConfirm: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(700 + theme.spacing.unit * 3 * 2)]: {
            width: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        alignItems: 'center',
        textAlign: 'center'
    },
    layoutResend: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        alignItems: 'center',
        textAlign: 'center'
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatarConfirm: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
        marginBottom: 10,
        backgroundColor: green[500],
    },
    avatarResend: {
        margin: theme.spacing.unit
    },
    back: {
        marginTop: theme.spacing.unit * 3,
    },
    check: {
        color: green[500]
    },
    progress: {
        marginTop: theme.spacing.unit * 7,
    },
    progress2: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    }
});


export default (connect(
    state => state.email,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(styles)(EmailConfirm)));
