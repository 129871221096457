import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout from './containers/Layout';
import Home from './containers/Home';
import Login from './containers/Login';
import Register from './containers/Register';
import Startup from './Startup';
import { requireAnonymous, requireAuth } from './containers/Authorize';
import NotFound from './components/NotFound';
import NavMenu from './components/NavMenu';
import EmailConfirm from './components/EmailConfirm';
import EmailResend from './components/EmailResend';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';


export default () => (

  <Startup>

    <Layout>
      <Switch>
        <Route exact path='/' component={Home} />

        <Route path='/login' component={requireAnonymous(Login)} />
        <Route path='/register' component={requireAnonymous(Register)} />


        <Route exact path='/emailConfirm' component={requireAnonymous(EmailConfirm)} />
        <Route exact path='/emailResend' component={requireAnonymous(EmailResend)} />

        <Route exact path='/forgotPassword' component={requireAnonymous(ForgotPassword)} />
        <Route exact path='/resetPassword' component={requireAnonymous(ResetPassword)} />



        <Route component={NotFound} />
      </Switch>
    </Layout>

  </Startup>
);
