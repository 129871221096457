import React from 'react';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';



const Footer = (props) => {

  const { classes } = props;
  return (

    <div className={classes.footer}>
      <Typography variant="subtitle1" color="textSecondary">
        JWebsite - Footer
        </Typography>
    </div>


  );
}

const styles = theme => ({

  footer: {

    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.appBar


  },
});

export default withStyles(styles)(Footer);