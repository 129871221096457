import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { getErrorsForId } from "../helpers/errorHelper";
import Typography from '@material-ui/core/Typography';

export const renderErrors = (id, props) => {

  if (props && props.errors) {
    let errors = getErrorsForId(id, props.errors);
    return (
      <ul style={listStyle}>
        <Wrapper id={id}>
          {errors.map(error => (
            <li style={errorStyle} className="station" key={error.message}>{error.message}</li>
          ))}
        </Wrapper>
      </ul>
    );
  }

  return null;
}

export const Wrapper = props => {

  if (props.id === '*') {
    return (
      <Typography variant="body1">
        {props.children}
      </Typography>
    );
  } else {
    return (
      <FormHelperText id="component-error-text">
        {props.children}
      </FormHelperText>
    );
  }
}


const listStyle = {
  listStyleType: 'none',
  padding: 0,
  marginTop: 0,
};

const errorStyle = {
  color: 'red',
  paddingBottom: '2px',
}