export const passwordConstants = {

    EMAIL_PASSWORD_RESET_REQUEST: 'EMAIL_PASSWORD_RESET_REQUEST',
    EMAIL_PASSWORD_RESET_SUCCESS: 'EMAIL_PASSWORD_RESET_SUCCESS',
    EMAIL_PASSWORD_RESET_FAILURE: 'EMAIL_PASSWORD_RESET_FAILURE',

    RESET_STATE: 'RESET_STATE',

    PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
    PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
    PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE'

 
};

export default passwordConstants;
