

export const Environment = "production"; // "production"


const apiUrl = () => {
    if (Environment === "dev") {
        return "https://localhost:44398/JWebsite";
    } else {
        return "https://api.jrdn.tech/JWebsite";
    }
}


export default (apiUrl());









