import constants from '../constants/authConstants';

const authHelper = {
    
    storeAuth: (auth) => {

        localStorage.setItem(constants.EMAIL_ADDRESS, auth.email);
        localStorage.setItem(constants.ACCESS_TOKEN, auth.accessToken);
        localStorage.setItem(constants.REFRESH_TOKEN, auth.refreshToken);
        localStorage.setItem(constants.USER_ID, auth.id);
    
    },

    getAuth: async() => {

        var auth = {};
    
        auth.email = await localStorage.getItem(constants.EMAIL_ADDRESS);
        auth.accessToken = await localStorage.getItem(constants.ACCESS_TOKEN);
        auth.refreshToken = await localStorage.getItem(constants.REFRESH_TOKEN);
        auth.id = await localStorage.getItem(constants.USER_ID);
    
        for (let key in auth) {
            if (auth[key] == null || auth[key] == undefined) {
                return null;
            }
        }
        
        return auth;
    },

    deleteAuth: () => {
    
        localStorage.removeItem(constants.EMAIL_ADDRESS);
        localStorage.removeItem(constants.ACCESS_TOKEN);
        localStorage.removeItem(constants.REFRESH_TOKEN);
        localStorage.removeItem(constants.USER_ID);
    }

};

export default authHelper;