import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { bindActionCreators } from "redux";
import { actionCreators } from "../actions/authActions";
import { connect } from "react-redux";
import Home from "@material-ui/icons/Home";
import Collapse from "@material-ui/core/Collapse";
import StarBorder from "@material-ui/icons/StarBorder";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const styles = theme => ({
  root: {

    paddingBottom: theme.spacing.unit * 2
  },

  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: {
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "block"
    }
  },
  link: {
    color: "inherit"
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  link: {
    color: "white",
    ":hover": {
      textDecoration: "none",
      color: "red"
    }
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  },
  drawer: {
    width: "33%"
  }
});

class NavMenu extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  toggleDrawer = open => () => {
    this.setState({
      drawer: open
    });
  };

  handleMenuLogout = () => {
    this.handleMenuClose();
    this.props.logout();
  };

  handleClick = e => {
    e.preventDefault();
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMenu = () => {
      if (this.props.authenticated) {
        return (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={isMenuOpen}
            onClose={this.handleMenuClose}
            getContentAnchorEl={null}
          >
            <MenuItem onClick={this.handleMenuClose} component={Link} to="/">
              Settings
            </MenuItem>
            <MenuItem onClick={this.handleMenuLogout} component={Link} to="#">
              Logout
            </MenuItem>
          </Menu>
        );
      } else {
        return (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={isMenuOpen}
            onClose={this.handleMenuClose}
            getContentAnchorEl={null}
          >
            <MenuItem
              onClick={this.handleMenuClose}
              component={Link}
              to="/login"
            >
              Login
            </MenuItem>
            <MenuItem
              onClick={this.handleMenuClose}
              component={Link}
              to="/register"
            >
              Sign Up
            </MenuItem>
          </Menu>
        );
      }
    };

    const renderDrawer = (
      <Drawer open={this.state.drawer} onClose={this.toggleDrawer(false)}>
        <div
          tabIndex={0}
          role="button"
          onClick={this.toggleDrawer(false)}
          onKeyDown={this.toggleDrawer(false)}
        >
          <div className={classes.list}>
            <List>
              <Link to="/">
                <ListItem button>
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <ListItemText primary={"Home"} />
                </ListItem>
              </Link>
            </List>

            <Divider />
            <List>
              {["All mail", "Trash", "Spam"].map((text, index) => (
                <ListItem button key={text}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}

              <ListItem button onClick={this.handleClick}>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText inset primary="Inbox" />
                {this.state.open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <ListItemText inset primary="Starred" />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </div>
        </div>
      </Drawer>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <div className={classes.sectionMobile}>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Open drawer"
                onClick={this.toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              {renderDrawer}
            </div>

            <Typography
              className={classes.title}
              variant="h5"
              color="inherit"
              noWrap
            >
              JWebsite
            </Typography>

            <div className={classes.grow} />

            <div className={classes.sectionDesktop}>
              <Button color="inherit" component={Link} to="/">
                Home
              </Button>

              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        {renderMenu()}
      </div>
    );
  }
}

export default connect(
  state => state.auth,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(styles)(NavMenu));
