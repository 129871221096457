import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../actions/authActions";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import blue from "@material-ui/core/colors/blue";
import Collapse from "@material-ui/core/Collapse";
import Icon from '@material-ui/icons/Chat';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/ExpandMore';
import LessIcon from '@material-ui/icons/ExpandLess';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Grid from "@material-ui/core/Grid";
import '../resources/css/DarkScrollbar.css';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import deepPurple from '@material-ui/core/colors/deepPurple';
import * as signalR from '@aspnet/signalr';
import apiURL from '../constants/config';


class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      messages: [],
      message: ''

    };
  }

  async componentWillMount() {

    /*let msgArray = [];
    for (let i = 0; i < 100; i++) {
      msgArray.push({ id: i, name: ('name ' + i), content: ('test mes swwwww wwww wwwww wwwwwwwww ') });
    }

    this.setState({ messages: msgArray });*/

    await this.props.getAuth();

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(apiURL + "/chat", { accessTokenFactory: () => this.props.auth.accessToken })
      .build();

    await this.connection.start()
      .then(() => console.log("connected"))
      .catch(error => { console.log("error: ", error) });

    this.connection.on("Initialize", (messages) => {

      this.setState({ messages: messages })

      this.scrollToBottom();

    })

    this.connection.on("Message", (message) => {

      this.setState({ messages: [...this.state.messages, message] })

      this.scrollToBottom();

    })

    await this.connection.invoke("JoinRoom", 2);


  }



  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    //this.scrollToBottomSmooth();
  }
  scrollToBottom() {
    this.chatBottom.parentNode.scrollTop = this.chatBottom.parentNode.scrollHeight;
  }

  scrollToBottomSmooth() {
    //this.chatBottom.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }


  handleClick = e => {
    e.preventDefault();
    this.setState(state => ({ open: !state.open }));
  };


  handleInputChange = field => e => {

    this.setState({ [field]: e.target.value });

  }

  handleKeyPress = async (event) => {

    if (event.key == 'Enter') {

      console.log("sending: ", this.state.message);
      await this.connection.invoke("SendMessage", 2, this.state.message);

      this.setState({ message: "" });

    }
  }


  getColor = id => {

    switch (id % 10) {

      case 0:
        return 'blue';
      case 1:
        return 'red';
      case 2:
        return 'cyan';
        break;
      case 3:
        return 'green';
        break;
      case 4:
        return 'purple';
        break;
      case 5:
        return 'yellow';
        break;
      case 6:
        return 'yellow';
        break;

      default:
        return 'white';

    }

  }

  render() {

    const { classes } = this.props;
    const { messages } = this.state;

    const chatContainer = () => {

      return (
        <>
          <Grid container className={classes.chatContainer}>
            {messages.map((message) =>
              <Grid item xs={12} key={message.messageId}>

                <Divider className={classes.divider} />

                <Typography className={classes.messageContainer}>
                  <b style={{ color: this.getColor(message.userId) }}>{message.displayName}:</b> {message.content}
                </Typography>

              </Grid>
            )}
            <div ref={el => { this.chatBottom = el; }} />
          </Grid>

          <TextField
            placeholder={"Send Message" + (this.props.authenticated ? "" : " as Guest")}
            variant="filled"
            margin="none"
            className={classes.textField}
            inputProps={{ className: classes.noPadding, onKeyPress: this.handleKeyPress, value: this.state.message }}
            onChange={this.handleInputChange("message")}
          />
        </>

      );

    }

    const expandButton = () => {
      if (this.state.open) {
        return <MoreIcon />;
      } else {
        return <LessIcon />;
      }
    }




    return (

      <div className={classes.root}>

        <Button variant="contained" color="secondary" className={classes.cardHeader} onClick={this.handleClick}>

          <Grid container>
            <Grid item xs={2} className={classes.left}>
              <Icon className={classes.rightIcon} />
            </Grid>
            <Grid item xs={5} className={classes.left}>
              <div>Live Chat</div>
            </Grid>

            <Grid item xs={5} className={classes.right}>

              {expandButton()}

            </Grid>
          </Grid>


        </Button>

        <Collapse in={this.state.open} timeout="auto">
          <Card className={classes.card}>

            <CardContent className={classes.cardContent}>
              {chatContainer()}
            </CardContent>

          </Card >
        </Collapse>

      </div>
    );
  }
}

const styles = theme => ({


  purpleAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },

  noPadding: {

    padding: '5px',
    height: '25px',

  },
  left: {
    textAlign: 'left'
  },

  right: {
    textAlign: 'right'
  },

  root: {
    width: "270px",
    position: "fixed",
    bottom: 0,
    right: 0,
    marginRight: theme.spacing.unit,
  },

  button: {
    bottom: 0,
    left: 0,
  },

  rightIcon: {
    marginRight: theme.spacing.unit,
  },

  card: {
    width: '100%',
    height: "400px",
  },

  cardHeader: {
    width: '100%',
    height: '20px',
  },

  chatContainer: {
    overflowY: 'scroll',
    height: '350px',
    wordWrap: 'break-word'

  },

  cardContent: {
    padding: '5px',

  },

  messageContainer: {

    paddingTop: '3px',
    paddingBottom: '3px'

  },

  divider: {
    marginRight: '5px'
  },

  textField: {
    width: '100%',
    paddingTop: '5px'

  }

});

export default connect(
  state => state.auth,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(styles)(Chat));


