export const emailConstants = {
    CONFIRM_REQUEST: 'CONFIRM_REQUEST',
    CONFIRM_SUCCESS: 'CONFIRM_SUCCESS',
    CONFIRM_FAILURE: 'CONFIRM_FAILURE',

    RESEND_REQUEST: 'RESEND_REQUEST',
    RESEND_SUCCESS: 'RESEND_SUCCESS',
    RESEND_FAILURE: 'RESEND_FAILURE',

    RESET_STATE: 'RESET_STATE'
};

export default emailConstants;
