


export const getErrorsForId = (id, errs) => {

  let errors = [];

  if ((errs === undefined || errs.length === 0)) {
  } else {
    errs.forEach((error) => {
    if (error !== undefined && error.key === id) {
      errors.push(error);
    }
  });
}

return errors;
}


export const containsError = (errors, str) => {
  var found = false;
  if (errors) {
    errors.forEach((error) => {
      if (error.message.includes(str)) {
        found = true;
      }
    });
  }
  return found;
}


export const formatErrors = (error) => {

  let errors = [];

  if (error.response) {

    switch (error.response.status) {

      case 400:
        errors = error.response.data.errors;
        break;

      case 500:
        errors.push({ key: "*", message: "Internal server error" });
        break;

      default:
        errors.push({ key: "*", message: "Error: " + error.response.status });
        break;
    }

  } else {
    errors.push({ key: "*", message: "No response from server" });
  }

  return errors;

} 
