import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../actions/authActions';
import { renderErrors } from "../components/InputErrors";
import renderInputField from "../components/BasicTextField";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { containsError } from "../helpers/errorHelper";
import Divider from '@material-ui/core/Divider';
import OAuth from '../components/OAuth';


class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputFields: [
        {
          id: 'Email',
          name: 'Email Address',
          autoComplete: "email",
          required: true
        },
        {
          id: 'Password',
          name: 'Password',
          type: 'password',
          autoComplete: "current-password",
          required: true
        }
      ]
    };

    this.submitLogin = this.submitLogin.bind(this);
  }

  componentWillUnmount() {

    this.props.unmount();

  }

  submitLogin = e => {

    if (!this.props.loggingIn)
      this.props.login(this.state.inputFields);

    e.preventDefault();

  };


  renderLoginButton() {
    const { classes } = this.props;
    if (this.props.loggingIn) {
      return (
        <FormControl fullWidth>
          <CircularProgress className={classes.progress} size={50} />
        </FormControl>
      );
    } else {
      return (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={this.submitLogin}
        >
          Sign in
          </Button>
      );
    }
  }

  renderCustomErrors() {

    if (containsError(this.props.errors, "has not been confirmed")) {
      return (
        <Typography variant="body1" paragraph>
          <Link to="/emailResend">Click here</Link> to send another confirmation email
        </Typography>
      );
    }

    return null;
  }



  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>

            <LockIcon className={classes.icon} />

            <Typography variant="h5">
              Sign in
            </Typography>
            <div className={classes.error}>
              {renderErrors('*', this.props)}
            </div>

            {this.renderCustomErrors()}

            <form className={classes.form}>

              {this.state.inputFields.map(inputField => (
                renderInputField(inputField, this)
              ))}
              <Typography variant="body1" color="primary" paragraph>
                <Link to="/forgotPassword">Forgot Password?</Link>
              </Typography>

              {this.renderLoginButton()}

            </form>

            <Divider className={classes.divider} />

            <OAuth />
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing.unit * 4
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing.unit * 8
    },

  },
  icon: {
    color: theme.palette.text.primary
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    backgroundColor: theme.palette.primary.paper
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    width: '90%',
    marginBottom: theme.spacing.unit * 3,

  },
  submit: {
    marginTop: theme.spacing.unit,
  },
  progress: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  error: {
    marginTop: theme.spacing.unit
  }
});



export default connect(
  state => state.auth,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(styles)(Login));
