import constants from "../constants/passwordConstants";
import { validateFields } from "../helpers/validationHelper";
import { setupRequest } from "../helpers/formHelper";
import { post, get } from "../services/apiService";

export const actionCreators = {
  emailPasswordReset: inputFields => async (dispatch, getState) => {
    dispatch({
      type: constants.EMAIL_PASSWORD_RESET_REQUEST
    });

    let errors = validateFields(inputFields);

    if (errors === undefined || errors.length === 0) {
      let request = setupRequest(inputFields);

      let response = await get("User", "SendPasswordReset", request);

      if (response.success) {
        dispatch({
          type: constants.EMAIL_PASSWORD_RESET_SUCCESS
        });
      } else {
        errors = response.data;
        dispatch({
          type: constants.EMAIL_PASSWORD_RESET_FAILURE,
          errors
        });
      }
    } else {
      dispatch({
        type: constants.EMAIL_PASSWORD_RESET_FAILURE,
        errors
      });
    }
  },

  reset: () => async (dispatch, getState) => {
    dispatch({
      type: constants.RESET_STATE
    });
  },

  resetPassword: inputFields => async (dispatch, getState) => {
    dispatch({
      type: constants.PASSWORD_RESET_REQUEST
    });

    let errors = validateFields(inputFields);

    if (errors === undefined || errors.length === 0) {
      let request = setupRequest(inputFields);

      let response = await post("User", "ResetPassword", request);

      if (response.success) {
        dispatch({
          type: constants.PASSWORD_RESET_SUCCESS
        });
      } else {
        errors = response.data;
        dispatch({
          type: constants.PASSWORD_RESET_FAILURE,
          errors
        });
      }
    } else {
      dispatch({
        type: constants.PASSWORD_RESET_FAILURE,
        errors
      });
    }
  }
};
