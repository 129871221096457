import React from "react";
import NavMenu from "../components/NavMenu";
import "../resources/css/Main.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Footer from "../components/Footer";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Chat from "./Chat";
import blue from "@material-ui/core/colors/blue";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },

  palette: {
    type: "dark",

    primary: {
      main: "#212121",
      appBar: "#212121",
      back: "#303030",
      paper: "#424242",
    },
    secondary: blue

  },

  sizing: {
    maxWidth: "1230px"
  }
});

const Layout = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      <CssBaseline />

      <MuiThemeProvider theme={theme}>
        <Grid
          container
          className={classes.layout}

          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <NavMenu />
          </Grid>

          <Grid item className={classes.body} xs={12}>
            {props.children}
          </Grid>

          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>

        <Chat />
      </MuiThemeProvider>
    </React.Fragment>
  );
};

const styles = () => ({
  layout: {
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.back,
    width: '100%'
  },

  body: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 6,
    minHeight: "calc(100vh - 141px)", //size of navbar and footer added
    maxWidth: theme.sizing.maxWidth,

  }

});

export default withStyles(styles)(Layout);
