import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({

  container: {

   
    

  },
});

const Home = props =>  {
  const { classes } = props;

  return (
    <React.Fragment>

        <Typography variant="h2" gutterBottom>
          Hello, world!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Lorem ipsum dolor sit amet, vero impedit ut sit. Vocent oporteat ocurreret vis id, et omnes possim adipiscing pri. Graeco dictas ea vix, ius ne elit quaeque vocibus. Assum contentiones has ad, mea ea ornatus noluisse temporibus. Ut gubergren urbanitas sed.

          Id accusata comprehensam conclusionemque sea. Ex aliquip viderer qualisque his. Novum noluisse ea vel. Quaestio constituam eam in, cibo dicit laoreet eu mea.

          Eu nibh probo sed. Ponderum gloriatur mei no. No movet doctus cum. Nam an inani invidunt persecuti, usu vulputate vituperatoribus an. An ius dico movet nonumy, cum autem ornatus delectus cu.

          Pri ne omnis illud verear, vix eu fabulas nusquam fabellas. Est et nullam legere timeam. Diam alia option mea an, per ut rebum choro vivendo, cu vis nibh diceret cotidieque. Cum in ipsum idque iuvaret, sit aliquip nusquam perfecto eu. Ut cum pertinax mandamus, qui dicat blandit cu.

          Per ei doctus deleniti reprehendunt, ne nec velit sonet persecuti, quas maiorum molestiae et usu. Mel aliquip debitis an, vix ne oblique nominati. Ex nullam recteque moderatius ius. His at discere argumentum definitionem.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <code>
          Lorem ipsum dolor sit amet, vero impedit ut sit. Vocent oporteat ocurreret vis id, et omnes possim adipiscing pri. Graeco dictas ea vix, ius ne elit quaeque vocibus. Assum contentiones has ad, mea ea ornatus noluisse temporibus. Ut gubergren urbanitas sed.

          Id accusata comprehensam conclusionemque sea. Ex aliquip viderer qualisque his. Novum noluisse ea vel. Quaestio constituam eam in, cibo dicit laoreet eu mea.

          Eu nibh probo sed. Ponderum gloriatur mei no. No movet doctus cum. Nam an inani invidunt persecuti, usu vulputate vituperatoribus an. An ius dico movet nonumy, cum autem ornatus delectus cu.

          Pri ne omnis illud verear, vix eu fabulas nusquam fabellas. Est et nullam legere timeam. Diam alia option mea an, per ut rebum choro vivendo, cu vis nibh diceret cotidieque. Cum in ipsum idque iuvaret, sit aliquip nusquam perfecto eu. Ut cum pertinax mandamus, qui dicat blandit cu.

          Per ei doctus deleniti reprehendunt, ne nec velit sonet persecuti, quas maiorum molestiae et usu. Mel aliquip debitis an, vix ne oblique nominati. Ex nullam recteque moderatius ius. His at discere argumentum definitionem.
          </code>
        </Typography>
        <Typography variant="body2" gutterBottom>
          Lorem ipsum dolor sit amet, vero impedit ut sit. Vocent oporteat ocurreret vis id, et omnes possim adipiscing pri. Graeco dictas ea vix, ius ne elit quaeque vocibus. Assum contentiones has ad, mea ea ornatus noluisse temporibus. Ut gubergren urbanitas sed.

          Id accusata comprehensam conclusionemque sea. Ex aliquip viderer qualisque his. Novum noluisse ea vel. Quaestio constituam eam in, cibo dicit laoreet eu mea.

          Eu nibh probo sed. Ponderum gloriatur mei no. No movet doctus cum. Nam an inani invidunt persecuti, usu vulputate vituperatoribus an. An ius dico movet nonumy, cum autem ornatus delectus cu.

          Pri ne omnis illud verear, vix eu fabulas nusquam fabellas. Est et nullam legere timeam. Diam alia option mea an, per ut rebum choro vivendo, cu vis nibh diceret cotidieque. Cum in ipsum idque iuvaret, sit aliquip nusquam perfecto eu. Ut cum pertinax mandamus, qui dicat blandit cu.

          Per ei doctus deleniti reprehendunt, ne nec velit sonet persecuti, quas maiorum molestiae et usu. Mel aliquip debitis an, vix ne oblique nominati. Ex nullam recteque moderatius ius. His at discere argumentum definitionem.
        </Typography>

    </React.Fragment>
  );

}


const old = (
  <React.Fragment>
    <h1>Hello, world!</h1>
    <p>Welcome to your new single-page application, built with:</p>
    <ul>
      <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
      <li><a href='https://facebook.github.io/react/'>React</a> and <a href='https://redux.js.org/'>Redux</a> for client-side code</li>
      <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
    </ul>
    <p>To help you get started, we've also set up:</p>
    <ul>
      <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
      <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
      <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
    </ul>
    <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>
  </React.Fragment>
);



export default withStyles(styles)(Home);
