import constants from '../constants/authConstants';
import apiURL from '../constants/config';
import axios from 'axios';
import { push } from 'react-router-redux'
import { validateFields } from '../helpers/validationHelper';
import { setupRequest } from '../helpers/formHelper';
import { formatErrors } from '../helpers/errorHelper';



export const post = async (route, action, parameters) => {

    let response = {
        success: false,
        data: {}
    };

    let url = apiURL + '/' + route + '/' + action;

    await axios.post(url, parameters, {

        headers: {
            'Content-Type': 'application/json',
        }

    }).then(function (res) {

        response.success = true;
        response.data = res.data;

    })
    .catch(function (error) {

        response.data = formatErrors(error);

    });

    return response;
}

export const get = async (route, action, parameters) => {

    let response = {
        success: false,
        data: {}
    };

    let url = apiURL + '/' + route + '/' + action;

    await axios.get(url, {

        params: parameters

    }).then(function (res) {

        response.success = true;
        response.data = res.data;

    })
    .catch(function (error) {

        response.data = formatErrors(error);

    });

    return response;
}




















