import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';

import authReducer  from './reducers/authReducer'
import registerReducer  from './reducers/registerReducer'
import emailReducer  from './reducers/emailReducer'
import passwordReducer  from './reducers/passwordReducer'

export default function configureStore(history, initialState) {

  const reducers = {
    auth: authReducer,
    register: registerReducer,
    email: emailReducer,
    password: passwordReducer
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
