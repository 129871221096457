import constants from "../constants/registerConstants";
import apiURL from "../constants/config";
import axios from "axios";
import { validateFields } from "../helpers/validationHelper";
import { setupRequest } from "../helpers/formHelper";
import { formatErrors } from "../helpers/errorHelper";

export const actionCreators = {
  register: inputFields => async (dispatch, getState) => {
    dispatch({ type: constants.REGISTER_REQUEST });

    let errors = validateFields(inputFields);

    if (errors === undefined || errors.length === 0) {
      let request = setupRequest(inputFields);

      const url = apiURL + "/User/Register";
      await axios
        .post(url, request, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(function (response) {
          dispatch({ type: constants.REGISTER_SUCCESS });
        })
        .catch(function (error) {
          errors = formatErrors(error);

          dispatch({ type: constants.REGISTER_FAILURE, errors });
        });
    } else {
      dispatch({ type: constants.REGISTER_FAILURE, errors });
    }
  },

  unmount: () => async (dispatch, getState) => {
    dispatch({ type: constants.UNMOUNT });
  }
};
